/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/*================================
Template Name: StreamView New Landing Page
================================== */

@media (max-width: 575.98px) {}

@media (min-width: 576px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 992px) {}

@media (min-width: 1440px) {}

@media (max-width: 1199.98px) {
    .latest-landing-about-sec .about-video-sec {
        max-height: 75%!important;
    }
}

@media (max-width: 991.98px) {
    .latest-landing-about-sec .about-details {
        padding-top: 3em!important;
    }
    .latest-landing-about-sec .about-video-sec {
        max-height: 89%!important;
    }
    .latest-landing-about-sec .about-details .about-title {
        font-size: 2rem!important;
    }
    .latest-landing-about-sec .about-details .about-desc {
        font-size: 1.2rem!important;
    }
    .latest-landing-sec {
        min-height: 50vh!important;
    }
    .our-download-card {
        width: 75%!important;
        left: 42%!important;
    }
    .latest-download-sec .download-details {
        padding-top: 5em!important;
    }
    .latest-download-sec .download-details .download-title {
        font-size: 2rem!important;
        line-height: 1.3!important;
    }
    .latest-download-sec .download-details .download-desc {
        font-size: 1.2rem!important;
    }
    .latest-download-sec .mobile-img {
        margin-left: -2em!important;
    }
    .book-img {
        height: 4em!important;
    }
    .our-download-card .our-download-card-text .download-sub-title {
        font-size: 1em!important;
    }
    .our-download-card .our-download-card-text .download-sub-desc {
        font-size: 0.9em!important;
    }
    .latest-watch-everywhere-sec .watch-everywhere-details {
        padding-top: 4.5em!important;
    }
    .latest-watch-everywhere-sec .watch-everywhere-details .watch-everywhere-title {
        font-size: 2rem!important;
    }
    .latest-watch-everywhere-sec .watch-everywhere-details .watch-everywhere-desc {
        font-size: 1.2rem!important;
    }
    .footer-sec .footer-sec-card .footer-site-sec {
        width: 100%!important;
        padding-top:0!important;
    }
    .footer-link-item {
        width: 50%!important;
    }
    .language-dropdown {
        width: 150px!important;
    }
    .latest-watch-everywhere-sec .watch-everywhere-video-sec {
        max-width: 60%!important;
    }
    .latest-home-banner-video {
        height: 49.5vh!important;
    }
    .latest-home-banner-img {
        height: 49.5vh!important;
    }
}


@media (max-width: 767.98px) {
    .latest-landing-sec {
        min-height: 65vh!important;
    }
    .latest-banner-content-info {
        max-width: 350px!important;
    }
    .latest-banner-content-info .banner-title {
        font-size: 2.3rem!important;
        max-width: 290px!important;
        margin:20px auto!important;
    
    }
    .latest-banner-content-info .banner-subtitle {
        font-size: 1.28rem!important;
    }
    .latest-banner-content-info .banner-desc {
        max-width: 307px!important;
        margin:1em auto!important;
        font-size: 1.43em!important;
    }
    .banner-theme-form {
        display: block!important;
        text-align: center;
    }
    .banner-theme-form .form-control {
        min-width: 325px!important;
        height: 55px!important;
        border-top: 0;
    }
    .latest-landing-sec .latest-landing-header {
        padding: 2em 2em!important;
    }
    .banner-theme-form .btn.btn-search {
        min-height: 48px!important;
    }
    .latest-banner-content {
        top: 55%!important;
    }
    .latest-landing-about-sec .tv-img{
        margin-top: 0!important;
    }
    .latest-landing-about-sec .about-details {
        padding-top: 0!important;
    }
    .latest-landing-about-sec .about-video-sec {
        max-height: 52%!important;
    }
    .latest-landing-about-sec .about-details {
        text-align: center;
    }
    .latest-landing-about-sec {
        padding: 40px 20px!important;
    }
    .latest-download-sec {
        padding: 40px 20px!important;
        padding-top: 20px!important;
    }
    .latest-download-sec .mobile-img {
        margin-left: 0em!important;
    }
    .our-download-card {
        left: 50%!important;
    }
    .latest-landing-about-sec .about-details .about-title {
        font-size: 2.5rem!important;
    }
    .latest-download-sec .download-details {
        padding-top: 2em!important;
        text-align: center;
    }
    .our-download-card {
        width: 80%!important;
    }
    .latest-watch-everywhere-sec{
        padding: 40px 20px!important;
    }
    .latest-watch-everywhere-sec .watch-everywhere-details {
        padding-top: 2em!important;
        text-align: center;
    }
    .faq-lists-sec .accordion {
        width: 100%!important;
        max-width: 100%!important;
    }
    .faq-lists-sec .accordion .card .heading-title {
        font-size: 0.65em!important;
    }
    .latest-faq-section {
        padding: 40px 0%!important;
    }
    .faq-lists-sec .accordion .card .card-body p {
        font-size: 1.4em!important;
    }
    .latest-faq-section .section-title {
        font-size: 2rem!important;
        max-width: 200px!important;
        margin: auto!important;
    }
    .footer-sec .footer-sec-card {
        padding: 40px 20px!important;
    }
    .faq-lists-sec .accordion {
        margin: 2em auto!important;
    }
    .latest-watch-everywhere-sec .watch-everywhere-video-sec {
        max-width: 58%!important;
    }
    .latest-home-banner-video {
        height: 64.5vh!important;
    }
    .latest-home-banner-img {
        height: 64.5vh!important;
    }
}

@media (max-width: 375px) {
    .latest-landing-sec {
        min-height: 65vh!important;
    }
    .banner-theme-form .form-control {
        min-width: 270px!important;
    }
    .latest-banner-content-info {
        max-width: 280px!important;
    }
}
.latest-landing-sec {
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    border-bottom: 8px solid #222;
}

/* .latest-landing-sec:before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
} */

.latest-landing-sec .latest-landing-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:2em 4em;
    position: absolute;
    width: 100%;
}

.latest-landing-sec .new-logo {
    height: 3em;
    width: auto;
}

.latest-landing-sec .signin-btn {
    background-color: #e50914;
    line-height: normal;
    padding: 7px 17px;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    border-radius: 3px;
}

.latest-banner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.banner-theme-form {
    display: flex;
}

.banner-theme-form .form-control {
    min-width: 450px;
    height: 60px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    font-size: 1.1em;
}

.theme-form-sec {
    position: relative;
}

/* .banner-theme-form .form-label {
    position: absolute;
    color: #8c8c8c;
    font-size: 1.1em;
    font-weight: 600;
    z-index: 1;
    top: 18px;
    left: 10px;
   display: none;
} */

.banner-theme-form .form-control:focus::-webkit-input-placeholder {
    font-size: .75em;
    position: relative;
    top: -20px; 
}

.banner-theme-form .form-control:focus .banner-theme-form .form-control {
    font-size: .75em;
}

.banner-theme-form .form-control:focus .banner-theme-form .form-label{
    top: -20px!important; 
}

.banner-theme-form .form-control::-webkit-input-placeholder{
    color: #8c8c8c;
    font-size: 1em;
    font-weight: 600;
}

.banner-theme-form .form-control::placeholder{
    color: #8c8c8c;
    font-size: 1em;
    font-weight: 600;
}

.banner-theme-form .btn.btn-search {
    font-size: 1.625rem;
    min-height: 60px;
    margin: 0!important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 1px solid #333;
    color: #fff;
    background-color: #e50914;
    background-image: -moz- oldlinear-gradient(top,#e50914,#db0510);
    background-image: linear-gradient(to bottom,#e50914,#db0510);
    box-shadow: 0 1px 0 rgba(0,0,0,.45);
    padding: 0 1em;
}

.latest-banner-content-info {
    text-align:center;
    max-width: 640px;
    margin: 0 auto;
}

.latest-banner-content-info .banner-title{
    font-size: 3.5em;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 20px;
}

.latest-banner-content-info .banner-subtitle {
    font-size: 1.7em;
    margin-bottom: 20px;
}

.latest-banner-content-info .banner-desc {
    font-size: 1.2em;
    font-weight: 500
}

/*Latest About Section CSS*/

.latest-landing-about-sec {
    /* background-color: #000000; */
    border-bottom: 8px solid #222;
    padding: 60px 45px;
    background-position: center center;
    background-size: cover;
    position: relative;
    width: 100%;
    display: block;
    background-repeat: no-repeat;
}

.latest-landing-about-sec::before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
}

.latest-landing-about-sec .tv-img {
   width: 100%; 
   margin-top: -3em;
}

.latest-landing-about-sec .about-details {
    padding-top: 7.5em;
}

.latest-landing-about-sec .about-details .about-title{
    font-size: 3.3rem;
    line-height: 1.1;
    font-weight: 700;
    margin: 0 0 .4em;
}

.latest-landing-about-sec .about-details .about-desc{
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.4;
    margin: .75em 0 .25em;
}

.latest-landing-about-sec .tv-img-sec {
    position: relative;
    z-index: 2;
}

.latest-landing-about-sec .about-video-sec {
    width: 100%;
    height: 100%;
    max-width: 70%;
    max-height: 72%;
    position: absolute;
    top: 46%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.our-about-card-video {
    width: 100%;
}

.our-about-card-img{
    width: 100%;
}

/*Latest Download section CSS*/

.latest-download-sec{
    background-color: #000000;
    border-bottom: 8px solid #222;
    padding: 60px 45px;
    padding-top: 20px;
}

.latest-download-sec .mobile-img {  
    width: 100%;
    margin-left: -6em;
 }
 
 .latest-download-sec .download-details {
     padding-top: 9em;
 }
 
 .latest-download-sec .download-details .download-title{
     font-size: 3.3rem;
     line-height: 1.1;
     font-weight: 700;
     margin: 0 0 .4em;
 }
 
 .latest-download-sec .download-details .download-desc{
     font-size: 1.625rem;
     font-weight: 500;
     line-height: 1.4;
     margin: .75em 0 .25em;
 }
 
 .latest-download-sec .mobile-img-sec {
     position: relative;
 }

.our-download-card {
    border: 2px solid rgba(255,255,255,.25);
    box-shadow: 0 0 2em 0 #000;
    width: 60%;
    min-width: 15em;
    border-radius: .75em;
    padding: .5em .75em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 35%;
    bottom: 8%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000000;
}

.book-img {
    height: 6em;
    object-fit: cover;
}

.our-download-card-image{
    margin: 0 1em 0 0;
}

.our-download-card .our-download-card-text .download-sub-title{
    font-size: 1.1em;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.02em;
    margin-bottom: 0;
}

.our-download-card .our-download-card-text .download-sub-desc{
    font-size: 1em;
    color: #0071eb;
    font-weight: 400;
    margin-bottom: 0;
}

.our-download-info {
    display: flex;
    align-items: center;
}

.download-gif-img-sec .download-gif-img{
    max-width: 3.5em;
}

/*Latest Watch Everywhere Section CSS*/

.latest-watch-everywhere-sec {
    background-color: #000000;
    border-bottom: 8px solid #222;
    padding: 60px 45px;
}

.latest-watch-everywhere-sec .all-device-img {
   width: 100%; 
}

.latest-watch-everywhere-sec .watch-everywhere-details {
    padding-top: 9em;
}

.latest-watch-everywhere-sec .watch-everywhere-details .watch-everywhere-title{
    font-size: 3.3rem;
    line-height: 1.1;
    font-weight: 700;
    margin: 0 0 .4em;
}

.latest-watch-everywhere-sec .watch-everywhere-details .watch-everywhere-desc{
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.4;
    margin: .75em 0 .25em;
}

.latest-watch-everywhere-sec .all-device-img-sec {
    position: relative;
    z-index: 2;
}

.latest-watch-everywhere-sec .watch-everywhere-video-sec {
    width: 100%;
    height: 100%;
    max-width: 61%;
    max-height: 76%;
    position: absolute;
    top: 46%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.our-watch-everywhere-card-video {
    width: 100%;
}

/*Faq Section CSS*/

.latest-faq-section {
    padding: 70px 45px;
    position: relative;
    border-bottom: 8px solid #222;
    padding: 50px 5%;
    margin-bottom: 0;
    background: 0 0;
    color: #fff;
    background-color: #000000;
}

.latest-faq-section .section-title {
    font-size: 3.3rem;
    line-height: 1.1;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
}

.faq-lists-sec .accordion {
    width: 75%;
    margin: 4em auto;
    max-width: 815px;
}

.faq-lists-sec .accordion .card-header{
    background: #303030;
    padding: .8em 2.2em .8em 1.2em;
}

.faq-lists-sec .accordion .card {
    border:0;
    background-color: #000000;
    margin-bottom: 1em;
}

.faq-lists-sec .accordion .card .card-body {
    background: #303030;
    display: inline-block;
    padding: 1.2em;
}
.faq-lists-sec .accordion .card .card-body p{
    font-size: 1.6em;
    color: #ffffff;
    font-weight: 500;
    padding: 0 0.5em;
}

.faq-lists-sec .accordion .card .heading-title {
    font-size: 0.7em;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.form-align-center {
    justify-content: center;
}

.latest-banner-content-info-form .faq-desc{
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
}

/*Footer Section CSS*/
.footer-sec{
    background-color: #000000;
}

.footer-sec .footer-sec-card {
    padding: 70px 45px;
}

.footer-sec .footer-sec-card .footer-site-sec {
    margin: 0 auto;
    padding-top: 30px;
    width: 90%;
}

.footer-top-title {
    padding: 0;
    margin: 0 0 30px;
    font-size: 1em;
    color: #757575;
}

.footer-link-item {
    box-sizing: border-box;
    padding: 0;
    margin-bottom: 16px;
    display: inline-block;
    min-width: 100px;
    width: 25%;
    padding-right: 12px;
    vertical-align: top;
    color: #757575;
    font-size:1em;
}

.language-dropdown {
    text-indent: 0;
    padding: 0.8em;
    background-image: none;
    border: 1px solid #333;
    color: #999;
    font-size: 16px;
    background-color: transparent;
}

.footer-company-name {
    color: #757575;
    font-size:1em;
    margin-bottom: 0;
    padding-top: 1em;
}

.heading-title.collapsed::after {
    content: "+";
    font-size: 1.5em;
    position: absolute;
    right: 2rem;
}

.heading-title::after {
    content: "\2212";
    font-size: 1.5em;
    position: absolute;
    right: 2rem;
}

.latest-home-banner-img-sec{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.latest-home-banner-img{
    height: 98vh;
    width: 100%;
    object-fit: fill;
}

.latest-home-banner-img-sec::before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
}

.latest-home-banner-video-sec{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.latest-home-banner-video{
    height: 98vh;
    width: 100%;
    object-fit: fill;
}

.latest-home-banner-video-sec::before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
}
.content {
  position: relative;
  height: 37vw;
  margin-top: -40px; }
  .content__background, .content__background__shadow, .content__background__image, .content__area {
    position: absolute;
    top: 0;
    bottom: 0; }
  .content__background {
    left: 0;
    right: 0; }
    .content__background__shadow {
      left: 0;
      background: #000;
      width: 30%;
      z-index: 2; }
      .content__background__shadow:before {
        content: '';
        position: absolute;
        z-index: 10;
        background-image: linear-gradient(to right, #000, transparent);
        top: 0;
        bottom: 0;
        left: 100%;
        width: 275px; }
    .content__background__image {
      right: 0;
      width: 70%;
      height: 100%;
      background-position: center 10%;
      background-size: cover;
      z-index: 1; }
  .content__area {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 3; }
    .content__area__container {
      padding: 30px 70px;
      color: wheat; }
  .content__title {
    font-size: 45px;
    color: #fff;
    font-weight: 700; }
  .content__description {
    font-size: 18px;
    color: #999;
    margin-top: 20px;
    max-width: 500px; }
  .content__close {
    color: #fff;
    width: 40px;
    height: 40px;
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 30px; }

.slide-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 55px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  outline: 0;
  padding: 0;
  margin: 40px 0;
  z-index: 4; }
  .slide-button span {
    width: 25px;
    color: #fff;
    display: block;
    margin: 0 auto; }
  .slide-button--next {
    right: 0; }
    .slide-button--next span {
      -webkit-transform: rotateZ(-90deg);
              transform: rotateZ(-90deg); }
  .slide-button--prev {
    left: 0; }
    .slide-button--prev span {
      -webkit-transform: rotateZ(90deg);
              transform: rotateZ(90deg); }

.slider-wrapper {
  padding: 40px 0;
  overflow: hidden;
  position: relative; }

.slider {
  position: relative; }
  .slider__container {
    display: flex;
    padding: 0 55px;
    transition: -webkit-transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms, -webkit-transform 300ms ease 100ms;
    z-index: 3;
    width: 100%; }
  .slider:not(.slider--open) .item:hover .show-details-button {
    opacity: 1; }
  .slider:not(.slider--open) .item:hover {
    -webkit-transform: scale(1.5) !important;
            transform: scale(1.5) !important; }
  .slider:not(.slider--open):hover .item {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%); }
  .slider:not(.slider--open) .item:hover ~ .item {
    -webkit-transform: translateX(25%);
            transform: translateX(25%); }

.show-details-button {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity 300ms ease 100ms;
  background: transparent;
  border: 0;
  outline: none;
  width: 100%;
  cursor: pointer; }
  .show-details-button span {
    display: block;
    width: 14px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    color: white; }

.markcontent {
  box-sizing: border-box;
  border: solid 4px #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 4; }
  .markcontent:before, .markcontent:after {
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    top: 100%;
    left: 50%;
    margin-left: -13px;
    border-style: solid;
    border-width: 7px 13px 0 13px; }
  .markcontent:before {
    border-color: rgba(0, 0, 0, 0.15) transparent transparent transparent;
    margin-top: 5px; }
  .markcontent:after {
    margin-top: 4px;
    border-color: #fff transparent transparent transparent; }

.item {
  flex: 0 0 19.7%;
  transition: -webkit-transform 300ms ease 100ms;
  transition: transform 300ms ease 100ms;
  transition: transform 300ms ease 100ms, -webkit-transform 300ms ease 100ms;
  margin: 0 2px;
  position: relative;
  height: 175px;
  background-size: cover;
  background-position: center center; }
  .item img {
    width: 100%;
    vertical-align: top; }
  .item .slider-content-box {
    font-size: 0.7em;
    padding: 1em;
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), transparent);
    display: none; }
  .item:hover .slider-content-box {
    display: block; }
  .item .thumbarrow-sec .thumbarrow-white {
    display: none; }
  .item .thumbarrow-sec .thumbarrow-red {
    display: block; }
  .item .show-details-button span {
    display: none !important; }

.slider--open .item:hover .slider-content-box {
  display: none; }

.donate-title{
    color: #ffffff;
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: 1px;
}

.donate-title-sec{
    border-bottom: 3px solid #505050;
    padding-bottom: 0.8em;
}

.donate-title-sec p{
    margin-bottom: 0;
    font-size: 0.95em;
    font-weight: 400;
    color: #cbced1;
}

.donate-feature-img{
    width: 100%;
    max-width: 25em;
    margin:1.5em 0;
}

.donate-form-sec .form-label{
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.donate-form-sec .form-control {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 10px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  border:0!important;
}

.donate-form-sec .form-control::-webkit-input-placeholder{
    font-size: 14px;
    font-weight: 400;
}

.donate-form-sec .form-control::placeholder{
    font-size: 14px;
    font-weight: 400;
}

.height-auto{
    height: auto!important;
}

.donate-box{
    padding: 1em 3.4em;
}

.donate-feature-img-sec{
  text-align: center;
}

.donate-btn-sec{
    margin-top: 1.5em;
}

.donate-radio-btn{
    /* margin-top: 1.8em; */
}

.donate-radio-btn .custom-control{
    padding-left: 0;
}

.donate-radio-btn .custom-control-label{
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: #fff!important;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before{
    background-color: #e50914;
    border: 1px solid #e50914;
}

.donate-left-img{
    width: 100%;
}

.donate-box p span{
    font-size: 1.05em;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
}

.donate-box p{
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 500;
    color: #cbced1; 
    line-height: 1.8;
    letter-spacing: 0.5px;
    margin-top: 2em;
}

.donate-box h4{
    font-size: 1.1em;
    font-weight: 600;
    letter-spacing: 1px;
}


input[type=radio] {
    opacity: 0;
    width: 100%;
    height: 42px;
    background-color: blue;
    position: relative;
    z-index: 1;
  }
  
  .donate-amount-link .form-group{
    display: flex;
  }
  
  .donate-amount-link .input-container {
  }
  /* .donate-amount-link .input-container:first-child label {
    border-radius: 5px 0 0 5px;
  }
  .donate-amount-link .input-container:last-child label {
    border-radius: 0 5px 5px 0;
    border-right: 2px solid #CCC;
  }
   */
 .donate-amount-link label {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid #CCC;
    /* border-right: inherit; */
    top: 0;
    left: 0;
    /* font-family: arial; */
    color: #737373!important;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.8em 2em;
    font-size: 1.2em;
    font-weight: 600;
    line-height: unset!important;
    margin-bottom: 0!important;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .donate-amount-link input:checked + label {
    background-color: #e50914;
    top: 0;
    left: 0;
    border: 2px solid #e50914 !important;
    z-index: 2;
    color: #fff!important;
    border-radius: 10px;
  }

  .donate-amount-link [type="radio"]:checked + label:after, .donate-amount-link [type="radio"]:not(:checked) + label:after{
    background-color: unset;
    display: none;
  }

  .donate-amount-link  [type="radio"]:checked + label:before,
  .donate-amount-link  [type="radio"]:not(:checked) + label:before {
  display: none;
}
  

.donate-amount-link .form-group{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}

.donate-amount-text-field .input-group-text{
    padding: 0.5em 1em;
    font-size: 1.5em;
    font-weight: 600;
    background: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.donate-amount-text-field .form-control{
    font-size: 1.2em;
    font-weight: 600;
    background: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.donate-amount-text-field .form-control::-webkit-input-placeholder{
    font-size:15px;
}


.donate-amount-text-field .form-control::placeholder{
    font-size:15px;
}

.donate-amount-text-field .form-control:focus{
    outline: none!important;
    box-shadow: none!important;
}
  
.or-divider{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 1em;
}

.or-divider h6{
    font-size: 1.05em;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 0;
}

.donate-choose-payment-btn-sec {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.donate-card{
    border: 2px solid #CCC;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
}

.donate-card-img{
    width: 100%;
    /* max-width: 20em; */
}

.donate-choose-payment-mode h5{
    padding:1em 0;
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 500;
    color: #fff;
}

.donate-choose-payment-mode h5 span{
    padding-left: 0.2em;
    font-size: 12px;
    color: #cbced1;
}

.donate-paypal-img {
    width: 100%;
    max-width: 10em;
}

.donate-paypal{
    border: 2px solid #CCC;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
}

.donate-footer-desc-sec p {
    font-size: 1em;
    font-weight: 400;
    color: #cbced1;
}

.donate-footer-desc-sec p a{
    color: #4343ff;
}

.donate-feature-form{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.donate-feature-form .form-control{
    font-size: 1.2em;
    font-weight: 600;
    background: #fff;
    border-radius: 10px;
    padding: 0.8em 1em;
}

.donate-feature-form .form-control::-webkit-input-placeholder{
    font-size:15px;
}

.donate-feature-form .form-control::placeholder{
    font-size:15px;
}

.donate-feature-form .form-control:focus{
    outline: none!important;
    box-shadow: none!important;
}
