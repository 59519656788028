.donate-title{
    color: #ffffff;
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: 1px;
}

.donate-title-sec{
    border-bottom: 3px solid #505050;
    padding-bottom: 0.8em;
}

.donate-title-sec p{
    margin-bottom: 0;
    font-size: 0.95em;
    font-weight: 400;
    color: #cbced1;
}

.donate-feature-img{
    width: 100%;
    max-width: 25em;
    margin:1.5em 0;
}

.donate-form-sec .form-label{
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.donate-form-sec .form-control {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 10px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  border:0!important;
}

.donate-form-sec .form-control::placeholder{
    font-size: 14px;
    font-weight: 400;
}

.height-auto{
    height: auto!important;
}

.donate-box{
    padding: 1em 3.4em;
}

.donate-feature-img-sec{
  text-align: center;
}

.donate-btn-sec{
    margin-top: 1.5em;
}

.donate-radio-btn{
    /* margin-top: 1.8em; */
}

.donate-radio-btn .custom-control{
    padding-left: 0;
}

.donate-radio-btn .custom-control-label{
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: #fff!important;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before{
    background-color: #e50914;
    border: 1px solid #e50914;
}

.donate-left-img{
    width: 100%;
}

.donate-box p span{
    font-size: 1.05em;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
}

.donate-box p{
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 500;
    color: #cbced1; 
    line-height: 1.8;
    letter-spacing: 0.5px;
    margin-top: 2em;
}

.donate-box h4{
    font-size: 1.1em;
    font-weight: 600;
    letter-spacing: 1px;
}


input[type=radio] {
    opacity: 0;
    width: 100%;
    height: 42px;
    background-color: blue;
    position: relative;
    z-index: 1;
  }
  
  .donate-amount-link .form-group{
    display: flex;
  }
  
  .donate-amount-link .input-container {
  }
  /* .donate-amount-link .input-container:first-child label {
    border-radius: 5px 0 0 5px;
  }
  .donate-amount-link .input-container:last-child label {
    border-radius: 0 5px 5px 0;
    border-right: 2px solid #CCC;
  }
   */
 .donate-amount-link label {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid #CCC;
    /* border-right: inherit; */
    top: 0;
    left: 0;
    /* font-family: arial; */
    color: #737373!important;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.8em 2em;
    font-size: 1.2em;
    font-weight: 600;
    line-height: unset!important;
    margin-bottom: 0!important;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .donate-amount-link input:checked + label {
    background-color: #e50914;
    top: 0;
    left: 0;
    border: 2px solid #e50914 !important;
    z-index: 2;
    color: #fff!important;
    border-radius: 10px;
  }

  .donate-amount-link [type="radio"]:checked + label:after, .donate-amount-link [type="radio"]:not(:checked) + label:after{
    background-color: unset;
    display: none;
  }

  .donate-amount-link  [type="radio"]:checked + label:before,
  .donate-amount-link  [type="radio"]:not(:checked) + label:before {
  display: none;
}
  

.donate-amount-link .form-group{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 1rem;
}

.donate-amount-text-field .input-group-text{
    padding: 0.5em 1em;
    font-size: 1.5em;
    font-weight: 600;
    background: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.donate-amount-text-field .form-control{
    font-size: 1.2em;
    font-weight: 600;
    background: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.donate-amount-text-field .form-control::placeholder{
    font-size:15px;
}

.donate-amount-text-field .form-control:focus{
    outline: none!important;
    box-shadow: none!important;
}
  
.or-divider{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 1em;
}

.or-divider h6{
    font-size: 1.05em;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 0;
}

.donate-choose-payment-btn-sec {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}

.donate-card{
    border: 2px solid #CCC;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
}

.donate-card-img{
    width: 100%;
    /* max-width: 20em; */
}

.donate-choose-payment-mode h5{
    padding:1em 0;
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 500;
    color: #fff;
}

.donate-choose-payment-mode h5 span{
    padding-left: 0.2em;
    font-size: 12px;
    color: #cbced1;
}

.donate-paypal-img {
    width: 100%;
    max-width: 10em;
}

.donate-paypal{
    border: 2px solid #CCC;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
}

.donate-footer-desc-sec p {
    font-size: 1em;
    font-weight: 400;
    color: #cbced1;
}

.donate-footer-desc-sec p a{
    color: #4343ff;
}

.donate-feature-form{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}

.donate-feature-form .form-control{
    font-size: 1.2em;
    font-weight: 600;
    background: #fff;
    border-radius: 10px;
    padding: 0.8em 1em;
}

.donate-feature-form .form-control::placeholder{
    font-size:15px;
}

.donate-feature-form .form-control:focus{
    outline: none!important;
    box-shadow: none!important;
}